<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>C端数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>教师注册信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">注册信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="120px"
        ref="queryFormRef"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="赛区" prop="districtId">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.districtId"
                clearable
              >
                <el-option
                  v-for="item in dict_district"
                  :key="item.id"
                  :label="item.districtName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item
              label-width="120px"
              label="学校省市区"
              prop="schoolArea"
            >
              <el-cascader
                placeholder="请选择"
                clearable
                v-model="schoolArea"
                ref="schoolAreaRef"
                :options="areasData"
                style="width: 100%"
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'areaId',
                  children: 'areas',
                }"
                @change="provincesHandleChangeSearch"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="学校" prop="schoolId">
              <el-select
                ref="schoolSelectRef"
                style="width: 100%"
                placeholder="请选择"
                v-model="queryForm.schoolId"
                clearable
                filterable
              >
                <el-option
                  v-for="item in provincesSchoolListSearch"
                  :key="item.id"
                  :label="item.schoolName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item> </el-col
        ></el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getDataList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 注册信息列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">注册信息列表</span>
        </el-col>
        <!-- <el-col :span="15">
          <div class="div_algin_right">
            <el-button type="primary" @click="editExamineeTypeClicked()"
              >新增用户+</el-button
            >
          </div>
        </el-col> -->
        <el-col :span="15" style="text-align: right">
          <el-button
            type="primary"
            plain
            icon="el-icon-download"
            @click="exportTable()"
            >批量导出</el-button
          >
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="账号" prop="phone"></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <el-table-column
          label="性别"
          prop="sex"
          :formatter="genderFormatter"
        ></el-table-column>
        <el-table-column
          label="组别"
          prop="level"
          :formatter="groupFormatter"
        ></el-table-column>
        <el-table-column label="学校" prop="schoolName"></el-table-column>
        <el-table-column label="年级" prop="grade"></el-table-column>
        <el-table-column
          label="证件类型"
          prop="cardType"
          :formatter="cardTypeFormatter"
        ></el-table-column>
        <el-table-column label="证件号码" prop="idCard"></el-table-column>
        <!-- <el-table-column label="省市区" prop="provence">
          <template slot-scope="scope">
            <span
              >{{ scope.row.provinceName }}/{{ scope.row.cityName }}/{{
                scope.row.countyName
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="学校" prop="schoolName">
          <template slot-scope="scope">
            <span
              v-if="scope.row.fillschoolName && scope.row.fillschoolName !== ''"
              >{{ scope.row.fillschoolName }}</span
            >
            <span v-else>{{ scope.row.schoolName }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          label="年级"
          prop="grade"
          :formatter="gradeFormatter"
        ></el-table-column> -->
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <el-table-column label="注册时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="showDetailsClicked(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 编辑信息的dialog -->
    <el-dialog
      title="添加注册信息"
      :visible.sync="editInfoDialogVisible"
      width="60%"
      @close="editInfoDialogClose"
    >
      <el-form
        :model="editInfoForm"
        label-width="100px"
        ref="editInfoFormRef"
        :rules="editInfoRules"
        style="width: 100%"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="editInfoForm.phone" placeholder="请输入">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="editInfoForm.userName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="证件类型" prop="cardType">
              <el-select
                placeholder="请选择"
                v-model="editInfoForm.cardType"
                style="width: 100%"
              >
                <el-option
                  v-for="item in card_type"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件号码" prop="idCard">
              <el-input
                style="width: 100%"
                v-model="editInfoForm.idCard"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="出生日期" prop="dateBirth">
              <el-date-picker
                :picker-options="pickerOptions"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="editInfoForm.dateBirth"
                type="date"
                placeholder="选择日期"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="sex">
              <el-select
                placeholder="请选择"
                v-model="editInfoForm.sex"
                style="width: 100%"
              >
                <el-option
                  v-for="item in e_sex"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input
                v-model="editInfoForm.email"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学校地区" prop="schoolArea">
              <el-cascader
                placeholder="请选择"
                v-model="editInfoForm.schoolArea"
                ref="schoolAreaRef"
                :options="areasData"
                style="width: 100%"
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'areaId',
                  children: 'areas',
                }"
                @change="provincesHandleChange"
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="学校" prop="schoolId">
              <el-select
                ref="mailingInfoModelSchoolSelectRef"
                style="width: 100%"
                placeholder="请选择"
                v-model="editInfoForm.schoolId"
                clearable
                filterable
              >
                <el-option
                  v-for="item in provincesSchoolList"
                  :key="item.id"
                  :label="item.schoolName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年级" prop="grade">
              <el-select
                style="width: 100%"
                placeholder="请选择年级"
                v-model="editInfoForm.grade"
                clearable
                @change="userGradeselectClicked"
              >
                <el-option
                  v-for="item in dict_grade"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editInfoDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editInfo">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSysUserList, getSchoolListWithCity, saveSysUserAll, queryDistrictPage, getAllAreaList } from '@/http/api'
export default {
  data () {
    return {
      dict_district: [],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      timer: new Date().getTime(),
      queryForm: {
        phone: '',
        name: '',
        schoolId: '',
        identity: 1,
        pageNum: 1,
        pageSize: 10
      },
      schoolArea: null,
      editInfoForm: {
        phone: '',
        userName: '',
        sex: '',
        cardType: '',
        email: '',
        idCard: '',
        dateBirth: '',
        schoolArea: [],
        schoolId: '',
        grade: ''
      },
      editInfoRules: {
        phone: [{ required: true, message: '请输入', trigger: 'blur' }],
        userName: [{ required: true, message: '请输入', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择', trigger: 'change' }],
        cardType: [{ required: true, message: '请选择', trigger: 'change' }],
        email: [{ required: true, message: '请输入', trigger: 'blur' }],
        idCard: [{ required: true, message: '请输入', trigger: 'blur' }],
        dateBirth: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolId: [{ required: true, message: '请选择', trigger: 'change' }],
        grade: [{ required: true, message: '请选择', trigger: 'change' }]
      },

      editInfoSchoolList: [], // 学校数组
      editInfoDialogVisible: false,
      total: 0,
      dict_group: this.$userInfo.dict_group(),
      e_sex: this.$userInfo.getDataList(this, 'e_sex').then(value => { this.e_sex = value }),
      card_type: this.$userInfo.getDataList(this, 'card_type').then(value => { this.card_type = value }),
      dict_grade: this.$userInfo.getGradeData(),
      dataList: [],
      areasData: [],
      provincesSchoolList: [],
      provincesSchoolListSearch: []
    }
  },
  created () {
    this.getDataList()
    this.getDistrictList()
    this.getAllAreaList()
  },
  methods: {
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取列表信息
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      getSysUserList(this.queryForm).then((res) => {
        console.log('getSysUserList', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    editExamineeTypeClicked () {
      this.editInfoDialogVisible = true
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      if (!value) return
      this.provincesSchoolList = []
      this.editInfoForm.schoolName = ''
      this.editInfoForm.schoolId = ''
      this.editInfoForm.provinceCode = value[0]
      this.editInfoForm.cityCode = value[1]
      this.editInfoForm.areaCode = value[2]
      const deliveryAreaName = this.$refs.schoolAreaRef.getCheckedNodes()[0].pathLabels.join('/')
      const areaList = deliveryAreaName.split('/')
      this.editInfoForm.province = areaList[0]
      this.editInfoForm.city = areaList[1]
      this.editInfoForm.area = areaList[2]
      console.log('editInfoForm', this.editInfoForm)
      this.getSchoolListData(value, 2)
    },
    // 获取学校List
    getSchoolListData (schoolArea, type) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        if (type === 1) {
          this.provincesSchoolListSearch = res.data
        } else if (type === 2) {
          this.provincesSchoolList = res.data
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 用户年级选择转换组别
    userGradeselectClicked () {
      const gradeData = this.$userInfo.getUserGroupWithGrade(this.editInfoForm.grade)
      this.editInfoForm.group = gradeData.userGradeStr
      this.editInfoForm.level = gradeData.groupId
    },
    // 查看用户详情
    showDetailsClicked (rowData) {
      console.log('rowData', rowData)
      this.$router.push(
        {
          path: '/c_data_Management/c_player_registInfo_details',
          query: {
            userId: rowData.id
          }
        }
      )
    },
    // 保存用户信息
    editInfo () {
      this.$refs.editInfoFormRef.validate(async valid => {
        if (!valid) return
        if (!this.$xcUtils.checkPhone(this.editInfoForm.phone)) return this.$message.warning('请输入正确手机号')
        if (this.editInfoForm.cardType === '1') {
          if (!this.$xcUtils.checkIdCard(this.editInfoForm.idCard)) return this.$message.warning('请输入正确证件号')
        }
        if (!this.$xcUtils.checkEmail(this.editInfoForm.email)) return this.$message.warning('请输入正确邮箱')
        this.editInfoForm.schoolName = this.$refs.mailingInfoModelSchoolSelectRef.selectedLabel
        var sysUserDto = {}
        sysUserDto.cardType = this.editInfoForm.cardType
        sysUserDto.dateBirth = this.editInfoForm.dateBirth
        sysUserDto.email = this.editInfoForm.email
        sysUserDto.idCard = this.editInfoForm.idCard
        sysUserDto.phone = this.editInfoForm.phone
        sysUserDto.sex = this.editInfoForm.sex
        sysUserDto.userName = this.editInfoForm.userName
        var sysUserInfoDto = {}
        sysUserInfoDto.area = this.editInfoForm.area
        sysUserInfoDto.areaCode = this.editInfoForm.areaCode
        sysUserInfoDto.city = this.editInfoForm.city
        sysUserInfoDto.cityCode = this.editInfoForm.cityCode
        sysUserInfoDto.level = this.editInfoForm.level
        sysUserInfoDto.nationality = this.editInfoForm.nationality
        sysUserInfoDto.nationalityCode = this.editInfoForm.nationalityCode
        sysUserInfoDto.province = this.editInfoForm.province
        sysUserInfoDto.provinceCode = this.editInfoForm.provinceCode
        sysUserInfoDto.schoolId = this.editInfoForm.schoolId
        sysUserInfoDto.schoolName = this.editInfoForm.schoolName
        var saveForm = {}
        saveForm.sysUserDto = sysUserDto
        saveForm.sysUserInfoDto = sysUserInfoDto
        saveSysUserAll(saveForm).then((res) => {
          console.log('saveForm', res)
          this.editInfoDialogVisible = false
          this.$message.success('添加成功')
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 重置搜索条件
    reset () {
      this.schoolArea = null
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    genderFormatter (data) {
      var temp = '未设置'
      for (const key in this.e_sex) {
        if (Object.hasOwnProperty.call(this.e_sex, key)) {
          const element = this.e_sex[key]
          if (element.dictValue + '' === data.sex + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    cardTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.card_type) {
        if (Object.hasOwnProperty.call(this.card_type, key)) {
          const element = this.card_type[key]
          if (element.dictValue + '' === data.cardType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_group) {
        if (Object.hasOwnProperty.call(this.dict_group, key)) {
          const element = this.dict_group[key]
          if (element.dictValue + '' === data.level + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    gradeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue + '' === data.grade + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    editInfoDialogClose () {
      this.$refs.editInfoFormRef.resetFields()
    },
    exportTable () {
      const { pageNum, pageSize, ...newForm } = this.queryForm
      const downLoadUrl = `${this.$http.defaults.baseURL}erpUser/excelQuerySysUserList?${this.$xcUtils.serialize(newForm)}`
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl)
    },
    // 学校省市区选择
    provincesHandleChangeSearch (value) {
      console.log('value', value)
      if (!value) return
      this.provincesSchoolListSearch = []
      this.queryForm.schoolId = ''
      this.getSchoolListData(value, 1)
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.div_algin_right {
  text-align: right;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.edit-selecttype-backView {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.examinee-button-type {
  width: 200px;
  margin-bottom: 15px;
}
</style>
